import React from "react";

import { RenderComponent } from "../core/hooks/components";
import Layout from "./layout";
import Navi from "../components/navi";

// Override default console.log function in production
if (process.env.NODE_ENV === "production") {
   console.log = function () {};
}

class StoryblokEntry extends React.Component {
   static getDerivedStateFromProps(props, state) {
      if (state.story.uuid === props.pageContext.story.uuid) {
         return null;
      }

      return StoryblokEntry.prepareStory(props);
   }

   static prepareStory(props) {
      const story = Object.assign({}, props.pageContext.story);
      const globalNavi = Object.assign({}, props.pageContext.globalNavi);

      story.content = JSON.parse(story.content);
      globalNavi.content = JSON.parse(globalNavi.content);

      return { story, globalNavi };
   }

   constructor(props) {
      super(props);

      this.state = StoryblokEntry.prepareStory(props);
   }

   render() {
      let content = this.state.story.content;
      let globalNavi = this.state.globalNavi.content;
      let seo = {
         default: this.state.story,
         data: this.state.story.content.seo,
         featured_image: this.state.story.content.featured_image,
         robots: this.state.story.content.seo_robots,
         location: this.props.location,
      };

      return (
         <div>
            <Layout seo={seo} location={this.props.location} context={globalNavi} page={this.state.story}>
               <h1>{this.state.story.name}</h1>

               <RenderComponent blok={content} />

               {this.props.location.pathname === "/" && (
                  <aside>
                     <h2>Sommaire</h2>
                     <Navi navItems={globalNavi} />
                  </aside>
               )}
            </Layout>
         </div>
      );
   }
}

export default StoryblokEntry;
